import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolutionArea } from '@app/modules/company-management/modules/company-solutions/company-solutions.model';
import { Dictionary } from 'lodash';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { CACHE_EXPIRE_DISTANCE, EMPTY_UID } from '../constants';
import { Attachment, BaseGroupItem, BaseItem, Link, UUID } from '../models/common.model';
import { SolutionForm } from '../models/solutions.model';
import {
  ApiService,
  BaseCreateResponse,
  BaseErrorResponse,
} from './api.service';

export interface SolutionItem extends BaseItem {
  description?: string;
  attachments?: Attachment[];
  links?: Link[];
  favorite?: boolean;
}

interface SolutionGroupResponse extends BaseItem {
  solutions: SolutionItem[];
}

interface SolutionOptionsResponse {
  items: SolutionGroupResponse[];
}

export type SolutionGroup = BaseGroupItem<SolutionItem>;

@Injectable({
  providedIn: 'root',
})
export class SolutionsService {
  static readonly blockCid = 'SolutionsService';

  private _solutionsCache: Dictionary<{ data: SolutionForm; time: Date }> = {};

  constructor(private _apiService: ApiService) {
  }

  getClientSolutionsByIndustries(
    clientUid: string,
    industryUids: UUID[],
    subIndustryUids: UUID[],
    businessFunctionUid?: UUID,
    blockCid?: string
  ): Observable<SolutionGroup[]> {
    const formObj = {
      ...industryUids.reduce(
        (result: Dictionary<string>, value: string, index: number) => {
          result[`industryUid[${ index }]`] = value;
          return result;
        },
        {}
      ),
      ...(subIndustryUids || []).reduce(
        (result: Dictionary<string>, value: string, index: number) => {
          if (value) {
            result[`SubIndustryUids[${ index }]`] = value;
          }
          return result;
        },
        {}
      ),
    };
    if (businessFunctionUid && businessFunctionUid !== EMPTY_UID) {
      formObj.businessFunctionUid = businessFunctionUid
    }
    return this._apiService
      .get<SolutionOptionsResponse>(
        `fishbone/clients/${ clientUid }/solution-options`,
        {
          params: new HttpParams({
            fromObject: formObj,
          }),
        },
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.map((item) => ({
            uid: item.uid,
            name: item.name,
            items: item.solutions,
          }))
        ),
        catchError(() => of([]))
      );
  }

  getSolutionOptions(
    clientUid: string,
    blockCid?: string
  ): Observable<SolutionGroup[]> {
    return this._apiService
      .get<SolutionOptionsResponse>(
        `fishbone/clients/${ clientUid }/solution-options`,
        {},
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.map((item) => ({
            uid: item.uid,
            name: item.name,
            items: item.solutions,
          }))
        ),
        catchError(() => of([]))
      );
  }

  getSolutionAreas(
    clientUid: string,
    blockCid?: string
  ): Observable<SolutionArea[]> {
    return this._apiService
      .get<SolutionArea[]>(
        `fishbone/clients/${ clientUid }/solution-areas`,
        blockCid || SolutionsService.blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getSolution(
    uid: string,
    clientUid: string,
    force = false,
    useCache = false,
    blockCid?: string
  ): Observable<SolutionForm> {
    const cacheUid = this._getCacheUid(uid, clientUid);
    if (
      this._solutionsCache[cacheUid] != null &&
      (Date.now() - this._solutionsCache[cacheUid].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._solutionsCache[cacheUid].data);
    }

    return this._apiService
      .get<SolutionForm>(
        `fishbone/clients/${ clientUid }/solutions/${ uid }`,
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        map((solution) =>
          solution.solutionAreaUid != null
            ? solution
            : {
              ...solution,
              solutionAreaUid: EMPTY_UID,
            }
        ),
        tap((solution: SolutionForm) => {
          this._solutionsCache[cacheUid] = {
            data: solution,
            time: new Date(),
          };
        }),
        catchError(() => of(null))
      );
  }

  createSolutionArea(
    name: string,
    clientUid: string,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .post<BaseCreateResponse>(
        `fishbone/clients/${ clientUid }/solution-areas`,
        { name },
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  createSolution(
    clientUid: string,
    form: SolutionForm,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    if (form.solutionAreaUid === EMPTY_UID) {
      form.solutionAreaUid = undefined;
    }

    return this._apiService
      .post<BaseCreateResponse>(
        `fishbone/clients/${ clientUid }/solutions`,
        {
          ...form,
          attachments: this._squeezeAttachments(form.attachments),
        },
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateSolutionArea(
    areaUid: string,
    name: string,
    clientUid: string,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    return this._apiService
      .put<boolean>(
        `fishbone/clients/${ clientUid }/solution-areas/${ areaUid }`,
        { name },
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        map(() => ({ entityUid: areaUid })),
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  updateSolution(
    clientUid: string,
    form: SolutionForm,
    blockCid?: string
  ): Observable<BaseCreateResponse> {
    if (form.solutionAreaUid === EMPTY_UID) {
      form.solutionAreaUid = undefined;
    }
    this._solutionsCache[this._getCacheUid(form.uid, clientUid)] = null;

    return this._apiService
      .put<BaseCreateResponse>(
        `fishbone/clients/${ clientUid }/solutions/${ form.uid }`,
        {
          ...form,
          attachments: this._squeezeAttachments(form.attachments),
        },
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({
            entityUid: null,
            statusText: (error.error as BaseErrorResponse).detail,
          })
        )
      );
  }

  uploadSolutionFile(
    clientUid: string,
    uploadedFile: File,
    blockCid?: string
  ): Observable<Attachment> {
    const formData = new FormData();
    formData.append('uploadedFile', uploadedFile);

    return this._apiService
      .post<{ uid: string }>(
        `fishbone/clients/${ clientUid }/solutions/attachments`,
        formData,
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        map((data) => ({
          ...data,
          fileName: uploadedFile.name,
          contentType: uploadedFile.type,
          temporary: true,
        })),
        catchError(() => of(null))
      );
  }

  toggleVisibilitySolutions(
    solutionUid: string,
    visible: boolean,
    clientUid: string,
    blockCid?: string
  ): Observable<{ solutionUid: string; visible: boolean }> {
    return this._apiService
      .put<{ solutionUid: string; visible: boolean }>(
        `fishbone/clients/${ clientUid }/solutions/${ solutionUid }/visible`,
        { visible },
        blockCid || SolutionsService.blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  toggleFavoritesSolution(
    userUid:UUID,
    solutionUid: UUID,
    favorite: boolean,
    blockCid?: string
  ): Observable<{
    solutionUid: UUID,
    favorite: boolean
  }> {
    return this._apiService
      .put<{ solutionUid: string; favorite: boolean }>(
        `fishbone/users/${ userUid }/solutions/${ solutionUid }/favorite`,
        { favorite },
        blockCid || SolutionsService.blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  removeSolutionArea(
    areaUid: string,
    clientUid: string,
    blockCid?: string
  ): Observable<boolean> {
    return this._apiService
      .delete(`fishbone/clients/${ clientUid }/solution-areas/${ areaUid }`)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  removeSolution(
    areaUid: string,
    clientUid: string,
    blockCid?: string
  ): Observable<boolean> {
    return this._apiService
      .delete(
        `fishbone/clients/${ clientUid }/solutions/${ areaUid }`,
        blockCid || SolutionsService.blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  private _squeezeAttachments(attachments?: Attachment[]): Attachment[] {
    return attachments?.map((item) => ({
      uid: item.uid,
    }));
  }

  private _getCacheUid(uid: string, clientUid: string): string {
    return `${ clientUid }_${ uid }`;
  }
}
