import { BaseItem } from './common.model';
import { Region } from './region.model';
import { RevenueRange } from './revenue.model';

export enum IndustryCode {
  Industrial = 'INDL',
  Bank = 'BANK',
  Insurance = 'INS',
  Healthcare = 'HLTHCR',
}

export enum IndustryCategoryUid {
  Industrial = 'd6f48ccf-5baf-409e-b6d8-eb9bf5c654c9',
  Bank = 'e2b4ed2b-e893-4d1e-a8e3-68b420ecb7c1',
  Insurance = 'd470cbb3-9757-4f04-a325-e20a2030cdf6',
  Healthcare = 'f459809e-b367-4393-ae87-66d3c246dacf',
}

export interface Sic extends BaseItem {
  code: string;
  defaultRegionUid?: string;
}

export interface Industry extends BaseItem {
  subIndustries?: Industry[];
  classifications?: Sic[];
  category?: IndustryCategory;
  defaultRegionUid?: string;
}

export interface IndustryCategory {
  code: IndustryCode;
  uid: string;
}

export interface IndustryDetails {
  uid: string;
  description?: string;
}

export interface IndustryFactor {
  uid: string;
  title: string;
  text: string;
}

export enum IndustryFactorTypes {
  External,
  Internal,
  Disruptor,
  Risk,
}

export interface IndustryReportsGroup {
  code: string;
  name: string;
  reports: IndustryReportLink[];
}

export interface IndustryReportLink {
  code: string;
  name: string;
  staticLink?: string;
  generatorLink?: string;
}

export class IndustryRisk {
  subtitle: string;
  description: string;
}

export interface IndustryDataSet {
  industry: Industry;
  subIndustry?: Industry;
  sic?: Sic;
  revenue: RevenueRange;
  region: Region;
}

export interface FullIndustryDataSet extends IndustryDataSet {
  revenueLabel?: string;
  industryUrl: string;
  industryName: string;
}

export interface FactorsRisksTrends {
  externalTrends: IndustryFactor[];
  internalTrends: IndustryFactor[];
  marketDistruptors: IndustryFactor[];
  risks: IndustryFactor[];
  updatedDate: string;
}

export interface IndustryFactorTrends {
  uid: string;
  type: IndustryFactorTypes;
  industryUid: string;
  subIndustryUid: string;
  title: string;
  description: string;
}

export type IndustryFactorFilter = {
  type: IndustryFactorTypes;
  industryUid: string;
  subIndustryUid?: string;
  titleLike?: string;
  descriptionLike?: string;
};
